import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import { LinkContainer } from 'react-router-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup'

import { RootState } from '../store'
import { CalendarEvent } from '../services/calendar'

import './EventList.scss'

type EventListProps = {
  events?: CalendarEvent[]
}

const EventList = ({ events }: EventListProps) => {
  const auth = useSelector((state: RootState) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

	return (
    <ListGroup>
      {events ? events.map((event) => (
        <LinkContainer to={`/event/${event.id}`} key={event.id}>
          <ListGroup.Item action variant={event.recurringEventId ? "secondary" : ""}>
            {event.summary}
            <span className="float-right">
            {event.allDay && (
              <i className="far fa-calendar"></i>
            )}
            {authExists && event.organizer && auth.email === event.organizer.email && (
              <i className="fas fa-user-circle self"></i>
            )}{" "}
            {event.recurringEventId && (
              <i className="fas fa-sync-alt"></i>
            )}
            </span>
          </ListGroup.Item>
        </LinkContainer>
      )) : null}
    </ListGroup>
  )
}

export default EventList;