/* global gapi */

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import * as _ from 'lodash'

dayjs.extend(duration)

export interface CalendarEvent extends gapi.client.calendar.Event {
  allDay?: boolean
}

export enum EventType {
  general = "General",
  oneOnOne = "One-on-one",
  status = "Status Update",
  info = "Information Sharing",
  decision = "Decision Making",
  brainstorm = "Brainstorm",
  problem = "Problem Solving",
  retro = "Retrospective",
  feedback = "Feedback"
}

export enum timeBucket {
  today = "Today",
  thisWeek = "This Week",
  later = "Later"
}

export type timeBucketStrings = keyof typeof timeBucket;

export interface CalendarState {
  events: {
    all: CalendarEvent[];
    bucket: Partial<{
      [key: string]: CalendarEvent[]
    }>;
    recurring: {
      [key: string]: CalendarEvent[]
    };
  }
  isLoading: boolean;
  error: string | null;
}

const calendarInitialState: CalendarState = {
  events: {
    all: [],
    bucket: {},
    recurring: {}
  },
  isLoading: false,
  error: null
}

function startLoading(state: CalendarState) {
  state.isLoading = true
}

const gapiConfig = {
  clientId: "533319794822-u4nmucodrrhvqhan18khqive7omf85np.apps.googleusercontent.com",
  apiKey: "AIzaSyCnhSKsW2FGrV4uw3HjZpYZo7FgXZstv6g",
  scope: "https://www.googleapis.com/auth/calendar.events",
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
}

export const fetchCalendar = createAsyncThunk(
  'calendar/fetchCalendar',
  async () => {
    await gapi.client.init(gapiConfig)
    const initialSignedIn = await gapi.auth2.getAuthInstance().isSignedIn.get()
    if (initialSignedIn) {
      const response = await gapi.client.calendar.events.list({
        'calendarId': 'primary',
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 100,
        'orderBy': 'startTime'
      })
      return response.result.items
    } else {
      return []
    }
  }
)

function findTimeBucket(event: CalendarEvent): timeBucket {
  const start = event.start || { date: '2020-01-01' }
  const startTime = start && 'date' in start ? start.date : start.dateTime
  const now = dayjs()
  const other = dayjs(startTime)
  const durationDays = dayjs.duration(other.diff(now)).asDays()

  if (now.isSame(other, 'day')) {
    return timeBucket.today
  } else if (durationDays <= 7) {
    return timeBucket.thisWeek
  } else {
    return timeBucket.later
  }
}

const calendarSlice = createSlice({
	name: 'calendar',
	initialState: calendarInitialState,
	reducers: {
    getCalendarStart: startLoading,
		getCalendarSuccess(state, { payload }: PayloadAction<any>) {
      state.events = payload
      state.isLoading = false
		},
    getCalendarFailure(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = action.payload
    }
	},
  extraReducers: builder => {
    builder.addCase(fetchCalendar.fulfilled, (state: CalendarState, action) => {
        const events = action.payload
        if (events) {
          const calendarEvents = _.map(events, (e: gapi.client.calendar.Event) => _.assign({}, e, {
             allDay: _.has(e, 'start.date'),
             startTime: _.has(e, 'start.date') ? _.get(e, 'start.date') : _.get(e, 'start.dateTime', '1969-01-01')
          }))
          const recurringEvents = calendarEvents.filter(e => e.recurringEventId)
          const uniqueRecurringEvents = _.uniqBy(recurringEvents, 'recurringEventId')
          const groupedRecurringEvents = _.groupBy(recurringEvents, 'recurringEventId')
          const normalEvents = _.sortBy(_.union(calendarEvents.filter(e => !e.recurringEventId), uniqueRecurringEvents), 'startTime')
          const bucketEvents = _.groupBy(normalEvents, findTimeBucket)
          state.events = {
            all: calendarEvents,
            bucket: bucketEvents,
            recurring: groupedRecurringEvents
          }
        }
    })
  }
})

export const { getCalendarStart, getCalendarSuccess, getCalendarFailure } = calendarSlice.actions

export default calendarSlice.reducer