import React from 'react';
import './loadingSpinner.css';

function LoadingSpinner() {
  return (
  	<div className="lds-ellipsis">
  		<div />
  		<div />
  		<div />
  		<div />
  	</div>
  )
}

export default LoadingSpinner;