// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";
import 'firebase/analytics';
import "firebase/firestore";

// Your app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyCnhSKsW2FGrV4uw3HjZpYZo7FgXZstv6g",
  authDomain: "echo.eonia.co",
  databaseURL: "https://eonia-echo.firebaseio.com",
  projectId: "eonia-echo",
  storageBucket: "eonia-echo.appspot.com",
  messagingSenderId: "533319794822",
  appId: "1:533319794822:web:91feb5b6747ecf4afb9107",
  measurementId: "G-PJCMK0DGR3",
  discoveryDocs: [
	  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
};

export const reduxFirebaseConfig = {
  enableLogging: false, // enable/disable Firebase Database Logging
  userProfile: 'users',
  useFirestoreForProfile: true
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore()

// Finally, export it to use it throughout your app
export { firebase };