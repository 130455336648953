import React from 'react';

import { useSelector } from 'react-redux'
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { RootState } from '../store'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header: React.FunctionComponent = () => {

  const auth = useSelector((state: RootState) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)
  const history = useHistory()
  const firebase = useFirebase()


  function handleLogout() {
      return firebase.logout().then(() => {
          history.push('/')
      })
  }

  return (
	<Navbar bg="dark" expand="sm" variant="dark">
	<Navbar.Brand href="/">Eonia Echo</Navbar.Brand>
	<Navbar.Toggle aria-controls="basic-navbar-nav" />
	<Navbar.Collapse id="basic-navbar-nav">
		<Nav className="ml-auto">
          {authExists ? (
              <React.Fragment>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                <Nav.Link href="/profile">Profile</Nav.Link>
              </React.Fragment>
          ) : (
              <Nav.Link href="/login">Login</Nav.Link>
          )}
		</Nav>
	</Navbar.Collapse>
	</Navbar>
  )
}

export default Header;