import { Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk'

import { configureStore, Action } from '@reduxjs/toolkit';
import {
	firebaseReducer,
	FirebaseReducer,
	FirestoreReducer
} from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import calendarReducer, { CalendarState } from './services/calendar'

interface UserProfile {
  displayName: string
  email: string
  photoURL: string | null
}

export interface EventValue {
  id?: string
  type: string
}

interface DBSchema {
  events: EventValue
  [name: string]: any
}

export interface RootState {
	firebase: FirebaseReducer.Reducer<UserProfile, DBSchema>;
	firestore: FirestoreReducer.Reducer;
	calendar: CalendarState;
}

const store = configureStore({
  reducer: {
  	firebase: firebaseReducer as Reducer,
  	firestore: firestoreReducer,
    calendar: calendarReducer
  }
});

// export type RootState = ReturnType<typeof store.getState>

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store;