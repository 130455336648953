import React from 'react';

import { firebase } from '../services/firebase';
import { UserIsNotAuthenticated } from '../utils/router'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/event',
  // We will display Google and Facebook as auth providers.
  signInOptions: [{
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    scopes: [
      'https://www.googleapis.com/auth/calendar.events'
    ]
  }]
};

const Login: React.FunctionComponent = () => {
  return (
    <Container fluid className="h-100 d-flex">
      <Row className="justify-content-center align-items-center mx-auto">
        <Col>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        </Col>
      </Row>
    </Container>
   )
}

export default UserIsNotAuthenticated(Login);