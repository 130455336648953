import React from 'react'

import { UserIsNotAuthenticated } from '../utils/router'

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const Home = () => {
	return (
	    <Container fluid className="h-100 d-flex mt-3">
	      <Row>
	          <Col>Eonia Echo</Col>
	      </Row>
	    </Container>
  )
}

export default UserIsNotAuthenticated(Home);