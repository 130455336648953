import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useFirestore, useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import * as _ from 'lodash'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import { RootState, EventValue } from '../store'
import { CalendarEvent, EventType } from '../services/calendar'


import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface DetailParams {
  event: CalendarEvent;
}

const CalendarEventDetail = ({ event }: DetailParams) => {
  if (event) {
    const dateKey = event.allDay ? 'date' : 'dateTime'
    const start = event.start ? dayjs(event.start[dateKey]) : dayjs()
    const end = event.end ? dayjs(event.end[dateKey]) : dayjs()

    return (
      <React.Fragment>
        <h1>{event && event.summary} <a href={event.htmlLink} target="_blank" rel="noreferrer">♯</a></h1>
        <h3>{start.format('L LT')} ({end.from(start, true)})</h3>
        <div>
          Attendees: {" "}
          {event.attendees && event.attendees.map((person, key) => (
            <React.Fragment key={key}>
              <Badge
                variant={event.creator && person.email === event.creator.email ? "primary" : "secondary"}
              >{person.email}</Badge>{" "}
            </React.Fragment>
          ))}

        </div>
        {event.description && 
          <Card body className="my-4">
            <div dangerouslySetInnerHTML={{__html: event.description}}/>
          </Card>
        }
      </React.Fragment>
    )

  } else {
    return <React.Fragment />
  }
}


interface ETDProps { eventType: keyof typeof EventType, updateType: Function }

const EventTypeDropdown = ({ eventType, updateType }: ETDProps) => {
  return (
      <Dropdown onSelect={(e) => { updateType(e) }}>
        <Dropdown.Toggle id="event-type-dropdown">
          {EventType[eventType]}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            Object.entries(EventType).map((evType: [string, EventType]) => (
              <Dropdown.Item
                key={evType[0]}
                eventKey={evType[0]}
                active={eventType === evType[0]}
              >{evType[1]}</Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    )
}


interface EDDProps { id: string }

const EventDocDetail = ({ id }: EDDProps) => {
  const firestore = useFirestore()

  useFirestoreConnect([
    { 
      collection: 'events',
      doc: id
    },
    { 
      collection: 'events',
      doc: id,
      subcollections: [
         { collection: "notes" }
      ],
      storeAs: 'notes'
    }
  ])

  const event = useSelector(
    ({ firestore: { data } }: RootState) => data.events && data.events[id]
  )

  const notes = useSelector(
    ({ firestore: { data } }: RootState) => data.notes
  )

  const docExists = isLoaded(event) && !isEmpty(event)

  console.log(docExists, event, notes)

  function addDoc() {
    const newDoc: EventValue = {
      type: 'general'
    }
    return firestore.collection('events').doc(id).set(newDoc)
  }

  function updateType(type: string) {
    return firestore.collection('events').doc(id).update({type: type})
  }

  const docDetails = docExists && (
    <EventTypeDropdown eventType={event.type as keyof typeof EventType} updateType={updateType} />
  )

  const addDocButton = (
    <Button onClick={addDoc}>
      <i className="far fa-calendar-plus"></i> Track Event
    </Button>
  )

  return docDetails || addDocButton
}

type TParams = { eventId: string }

const EventDetail = ({ match }: RouteComponentProps<TParams>) => {

  const { eventId } = match.params
  const calendarEvent = useSelector((state: RootState) => _.find(state.calendar.events.all, {'id': eventId}))

	return (
    <Col>
      <div>
        <i className="fas fa-chevron-left"></i> <Link to="/event">Events</Link>
      </div>
      { calendarEvent && <CalendarEventDetail event={calendarEvent} /> }
      <EventDocDetail id={eventId} />
    </Col>
  )
}

export default EventDetail;