import React from 'react';

import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import { RootState } from '../store'

const Header: React.FunctionComponent = () => {

  const auth = useSelector((state: RootState) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  return authExists ? (
    <ul>  
      <li>Display Name: {auth.displayName}</li>
      <li>Email: {auth.email}</li>
      <li>Photo: <img src={auth.photoURL ? auth.photoURL : ""} alt="profile" /></li>
    </ul>
  ) : <React.Fragment />
}

export default Header;