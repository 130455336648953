import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from "react-router-dom";

import { UserIsAuthenticated } from '../utils/router'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import EventList from './EventList'
import EventDetail from './EventDetail'
import LoadingSpinner from './LoadingSpinner'

import { RootState } from '../store'

import { fetchCalendar } from '../services/calendar'


const Events = () => {

  const dispatch = useDispatch()
  const calendar = useSelector((state: RootState) => state.calendar)

  useEffect(() => {
    if (!calendar.events.all.length) {
      dispatch(fetchCalendar())
    }
  })

  const eventLists = calendar.events && calendar.events.bucket ? (
    <Col>
      {Object.keys(calendar.events.bucket).map((value, index) => (
        <div key={index} className="mt-4">
          <h3>{value}</h3>
          {calendar.events.bucket && value in calendar.events.bucket && 
            <EventList events={calendar.events.bucket[value]} />
          }
        </div>
      ))}
    </Col>
  ) : null

	return (
	    <Container fluid className="h-100 d-flex mt-3">
	      <Row>
          <Col>
            <Route exact path="/event">
              { calendar.events ? eventLists : <LoadingSpinner />}
            </Route>

            <Route path="/event/:eventId" component={EventDetail} />
          </Col>
	      </Row>
	    </Container>
  )
}

export default UserIsAuthenticated(Events);