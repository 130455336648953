import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import { firebase, reduxFirebaseConfig } from '../../services/firebase'

import store from '../../store';

import Header from '../Header'
import Login from '../Login'
import Events from '../Events'
import Home from '../Home'
import Profile from '../Profile'
import ScrollToTop from '../ScrollToTop'

import Container from 'react-bootstrap/Container';

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
          firebase={firebase}
          config={reduxFirebaseConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}>
        <Router>
          <ScrollToTop />
          <Container fluid className="h-100 px-0">
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/event" component={Events} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/profile" component={Profile} />
            </Switch>
          </Container>
         </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
 }

export default App;
